import { computed } from "@vue/composition-api"

import useStore from "@/use/useStore"

export default function useIsViewerPresenter() {
  const { store } = useStore()

  const isViewerPresenter = computed(
    () => store.getters["group/isViewerPresenter"]
  )

  return { isViewerPresenter }
}
