var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jeopardai-challenge-form__input-section" },
    [
      _c("JeopardAiCountdown", {
        staticClass: "jeopardai-challenge-form__countdow",
        attrs: { time: _vm.countdown },
      }),
      _vm.isCorrect || _vm.isWrong
        ? [
            _c(
              "div",
              {
                staticClass: "jeopardai-challenge-form__status",
                class: {
                  "jeopardai-challenge-form__status--correct": _vm.isCorrect,
                  "jeopardai-challenge-form__status--wrong": _vm.isWrong,
                },
              },
              [_vm._v(" " + _vm._s(_vm.statusText) + " ")]
            ),
            _c("i", {
              staticClass:
                "mdi mdi-sword-cross jeopardai-challenge-form__icon jeopardai-challenge-form__icon--success",
              class: {
                "jeopardai-challenge-form__icon--success": _vm.isCorrect,
                "jeopardai-challenge-form__icon--fail": _vm.isWrong,
              },
            }),
          ]
        : _vm.isViewerChallenger
        ? _c("div", { staticClass: "jeopardai-challenge-form__text" }, [
            _vm._v(
              " Prove JeopardAI is wrong with a link or written explanation "
            ),
          ])
        : _c("div", { staticClass: "jeopardai-challenge-form__text" }, [
            _vm._v(
              " " +
                _vm._s(_vm.getShortenedName(_vm.challenger)) +
                " is challenging JeopardAI "
            ),
          ]),
      _vm.isViewerChallenger && _vm.isCorrect
        ? _c("div", { staticClass: "jeopardai-challenge-form__hint mt-4" }, [
            _vm._v(
              " You gained $" + _vm._s(_vm.addCommasToNumber(_vm.amount)) + "! "
            ),
          ])
        : _vm.isViewerChallenger && !_vm.isWrong
        ? _c("div", { staticClass: "jeopardai-challenge-form__hint mt-4" }, [
            _vm._v(
              " If you win, you will get $" +
                _vm._s(_vm.addCommasToNumber(_vm.amount)) +
                " "
            ),
          ])
        : _vm._e(),
      _vm.isViewerChallenger && !_vm.isWrong && !_vm.isCorrect
        ? _c("WeveTextarea", {
            staticClass: "jeopardai-challenge-form__input",
            attrs: { label: "", placeholder: "" },
            model: {
              value: _vm.input,
              callback: function ($$v) {
                _vm.input = $$v
              },
              expression: "input",
            },
          })
        : !_vm.isWrong && !_vm.isCorrect
        ? _c("div", {
            staticClass: "jeopardai-challenge-form__input mt-2",
            domProps: { innerHTML: _vm._s(_vm.link(_vm.data)) },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }