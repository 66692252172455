import { Question, JeopardAiUser, Answer } from "../types"
import { serverTimestamp } from "firebase/database"

import { MIN_SCORE } from "../constants"

export default class AnswerFactory {
  private question: Question
  constructor(question: Question) {
    this.question = question
  }
  create(
    user: JeopardAiUser,
    correct: boolean,
    answers?: Answer[]
  ): Omit<Answer, "timestamp"> & { timestamp: object } {
    const n = user.wager ?? this.question.amount ?? 0
    const challenge = user.challenge ?? false
    const a = correct ? 1 : -1
    const b = challenge ? 2 : 1

    const total =
      answers?.reduce((acc, val) => {
        if (val.teamId !== user.teamID) return acc
        if (val.amount != null) return acc + val.amount
        return acc
      }, 0) ?? 0

    const c = MIN_SCORE - total
    const d = n * a * b

    const amount = d >= c ? d : c

    return {
      timestamp: serverTimestamp(),
      userId: user?.id,
      questionId: this.question.id,
      round: this.question.round,
      correct,
      teamId: user.teamID ?? null,
      amount,
      challenge,
      wager: user.wager ?? null,
      answer: user.answer ?? null
    }
  }
}
