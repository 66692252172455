





























































import type { PropType } from "@vue/composition-api"
import { computed, defineComponent, toRefs, ref } from "@vue/composition-api"
import { watchDebounced } from "@vueuse/core"
import { WeveTextarea } from "@weve/ui"
import { set, child } from "firebase/database"
import Autolinker from "autolinker"

import { UserStatus } from "../enums"

import { QueueItem, JeopardAiUser, Question } from "../types"
import { addCommasToNumber } from "../helpers"

import User from "@shared/User"
import useViewer from "../use/useViewer"
import useIsViewerHostLike from "../use/useIsViewerHostLike"
import useJeopardAiQueueRef from "../use/useJeopardAiQueueRef"
import useJeopardAiRound from "../use/useJeopardAiRound"
import useJeopardAiState from "../use/useJeopardAiState"

import AnswerFactory from "../factories/AnswerFactory"
import JeopardAiCountdown from "./JeopardAiCountdown.vue"

export default defineComponent({
  name: "JeopardAiChallengeForm",
  components: {
    JeopardAiCountdown,
    WeveTextarea
  },
  props: {
    question: {
      type: Object as PropType<Question>,
      required: true
    },
    item: {
      type: Object as PropType<QueueItem>,
      required: true
    },
    challenger: {
      type: Object as PropType<JeopardAiUser>,
      required: true
    }
  },
  setup(props) {
    const { challenger, item, question } = toRefs(props)
    const { round } = useJeopardAiRound()
    const { state } = useJeopardAiState()
    const { ref: queueRef } = useJeopardAiQueueRef(round, state)
    const { viewer } = useViewer()
    const { isViewerHostLike } = useIsViewerHostLike()
    const countdown =
      challenger.value.challengeTimestamp + 30 * 1000 - Date.now()

    const input = ref(item.value?.data ?? "")

    const answerFactory = new AnswerFactory(question.value)

    const amount = computed<number>(
      () => answerFactory.create(challenger.value, true).amount ?? 0
    )
    const isCorrect = computed(
      () => challenger.value.status === UserStatus.CORRECT
    )
    const isWrong = computed(() => challenger.value.status === UserStatus.WRONG)
    watchDebounced(
      input,
      value => {
        const ref = child(queueRef.value, `${item.value.id}/data`)
        set(ref, value ?? "")
      },
      { debounce: 500 }
    )

    const statusText = computed(() =>
      isCorrect.value
        ? "Challenge won!"
        : isWrong.value
        ? "Challenge lost!"
        : null
    )

    const data = computed(() => item.value?.data ?? "")

    const isViewerChallenger = computed(
      () => viewer.value?.id === challenger.value?.id
    )

    return {
      countdown,
      isWrong,
      isCorrect,
      statusText,
      amount,
      input,
      data,
      isViewerChallenger,
      viewer,
      isViewerHostLike,
      getShortenedName: User.getShortenedName,
      link: Autolinker.link,
      UserStatus,
      addCommasToNumber
    }
  }
})
