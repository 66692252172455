var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "grid", staticClass: "jeopardai-users-grid" },
    [
      _vm._l(_vm.users, function (user) {
        var _obj, _obj$1
        return _c(
          "div",
          {
            key: user.id,
            staticClass: "jeopardai-users-grid__item",
            class:
              ((_obj = {}),
              (_obj["jeopardai-users-grid__item--active"] =
                user.status === _vm.UserStatus.ACTIVE ||
                user.status === _vm.UserStatus.WAITING),
              (_obj["jeopardai-users-grid__item--correct"] =
                user.status === _vm.UserStatus.CORRECT &&
                (!_vm.finale || _vm.isHost)),
              (_obj["jeopardai-users-grid__item--wrong"] =
                user.status === _vm.UserStatus.WRONG &&
                (!_vm.finale || _vm.isHost)),
              (_obj["jeopardai-users-grid__item--waiting"] =
                user.status === _vm.UserStatus.PENDING),
              _obj),
            style: {
              width: _vm.size + "px",
              height: _vm.size + "px",
            },
            attrs: { user: user },
          },
          [
            _c(
              "div",
              { staticStyle: { flex: "1", position: "relative" } },
              [
                user.status === _vm.UserStatus.CORRECT && !_vm.finale
                  ? _c("JeopardAiStars", {
                      staticClass: "jeopardai-users-grid__stars",
                      attrs: { size: _vm.size },
                    })
                  : _vm._e(),
                !user.mobile
                  ? _c(
                      "div",
                      {
                        staticClass: "jeopardai-users-grid__team-name",
                        class: {
                          "jeopardai-users-grid__team-name--current":
                            _vm.isCurrent(user.id),
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.getTeamName(user.teamID)) + " ")]
                    )
                  : _vm._e(),
                _c("AppUserCard", { attrs: { user: user, selectable: "" } }),
                user.deadline != null
                  ? _c("JeopardAiCountdown", {
                      staticClass: "jeopardai-users-grid__countdow",
                      attrs: { time: Math.max(user.deadline - Date.now(), 0) },
                    })
                  : _vm._e(),
                !_vm.finale && user.status !== _vm.UserStatus.PENDING
                  ? _c(
                      "div",
                      {
                        staticClass: "jeopardai-users-grid__badge",
                        class: {
                          "jeopardai-users-grid__badge--correct":
                            user.status === _vm.UserStatus.CORRECT,
                          "jeopardai-users-grid__badge--wrong":
                            user.status === _vm.UserStatus.WRONG,
                        },
                      },
                      [
                        user.status === _vm.UserStatus.CORRECT
                          ? [
                              _c("SvgIcon", {
                                attrs: {
                                  name: "team-tag-2",
                                  width: "24",
                                  height: "24",
                                },
                              }),
                              _vm._v(" Correct "),
                            ]
                          : user.status === _vm.UserStatus.WRONG
                          ? [
                              _c("SvgIcon", {
                                attrs: {
                                  name: "team-tag-1",
                                  width: "24",
                                  height: "24",
                                },
                              }),
                              _vm._v(" Incorrect "),
                            ]
                          : [_vm._v(" YOUR TURN ")],
                      ],
                      2
                    )
                  : _vm._e(),
                user.wager != null &&
                (_vm.isViewerHostLike ||
                  _vm.complete ||
                  user.teamID === _vm.viewer.teamID ||
                  _vm.double)
                  ? _c(
                      "div",
                      {
                        staticClass: "jeopardai-users-grid__wager",
                        class:
                          ((_obj$1 = {}),
                          (_obj$1["jeopardai-users-grid__wager--correct"] =
                            user.status === _vm.UserStatus.CORRECT &&
                            !_vm.finale),
                          (_obj$1["jeopardai-users-grid__wager--wrong"] =
                            user.status === _vm.UserStatus.WRONG &&
                            !_vm.finale),
                          _obj$1),
                      },
                      [
                        _c("div", [
                          _c(
                            "span",
                            {
                              staticClass: "jeopardai-users-grid__superscript",
                            },
                            [_vm._v("$")]
                          ),
                          _vm._v(
                            _vm._s(_vm.addCommasToNumber(user.wager)) + " "
                          ),
                        ]),
                        user.answer
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "jeopardai-users-grid__answer ml-2",
                              },
                              [_vm._v(" " + _vm._s(user.answer) + " ")]
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        )
      }),
      _c("ResizeObserver", { on: { notify: _vm.resize } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }