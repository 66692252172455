var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MissionCard",
    { staticClass: "jeopardai-wager" },
    [
      _vm.response.amount == null || !_vm.finale
        ? [
            _c("div", { staticClass: "jeopardai-wager__text" }, [
              _vm._v("How much do you want to wager?"),
            ]),
            _vm.options.length > 0
              ? _c("OptionsList", {
                  staticClass: "jeopardai-wager__options",
                  attrs: { show: "", options: _vm.options },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "option",
                        fn: function (ref) {
                          var option = ref.option
                          return [
                            _c(
                              "ChoiceBtn",
                              {
                                attrs: {
                                  inactive: _vm.response.amount != null,
                                  selected: option === _vm.response.amount,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.submitWagerAmount(option)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " $" +
                                    _vm._s(_vm.addCommasToNumber(option)) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2321105184
                  ),
                })
              : _vm._e(),
            _c("TextField", {
              staticClass: "jeopardai-wager__input",
              attrs: {
                transition: false,
                size: "sm",
                placeholder: "Type here ...",
                show: "show",
                status: "active",
                allowFreeSubmit: "",
                buttonText: "Submit",
                disabled: _vm.response.amount != null,
                maxTextLength: 100,
              },
              on: { onSubmit: _vm.submitWagerAmount },
            }),
          ]
        : [
            _c("div", { staticClass: "jeopardai-wager__text" }, [
              _vm._v("What is your answer?"),
            ]),
            _vm.question != null
              ? _c("div", { staticClass: "jeopardai-wager__question" }, [
                  _vm._v(" " + _vm._s(_vm.question.text) + " "),
                ])
              : _vm._e(),
            _c("TextField", {
              staticClass: "jeopardai-wager__input",
              attrs: {
                transition: false,
                size: "sm",
                placeholder: "Type here ...",
                show: "show",
                status: "active",
                allowFreeSubmit: "",
                buttonText: "Submit",
                disabled: _vm.response.answer != null,
                maxTextLength: 100,
              },
              on: { onSubmit: _vm.submitWagerAnswer },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }