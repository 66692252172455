var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jeopardai-countdown" },
    [
      _c("countdown", {
        staticClass: "jeopardai-countdown__time",
        attrs: { interval: 1000, time: _vm.time },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var _obj

              var hours = ref.hours
              var minutes = ref.minutes
              var seconds = ref.seconds
              var totalSeconds = ref.totalSeconds
              return [
                _c(
                  "span",
                  {
                    class:
                      ((_obj = {}),
                      (_obj["jeopardai-countdown__time--late"] =
                        totalSeconds === 0),
                      (_obj["jeopardai-countdown__time--alert"] =
                        totalSeconds <= 10 && totalSeconds > 0),
                      _obj),
                  },
                  [
                    hours ? [_vm._v(_vm._s(hours) + ":")] : _vm._e(),
                    minutes
                      ? [
                          _vm._v(
                            " " +
                              _vm._s(10 > minutes ? "" + minutes : minutes) +
                              ":"
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(
                      _vm._s(10 > seconds ? "0" + seconds : seconds) + " "
                    ),
                  ],
                  2
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }