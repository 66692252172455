
































































































import { computed, defineComponent, toRefs } from "@vue/composition-api"
import { ResizeObserver } from "vue-resize"

import type { PropType } from "@vue/composition-api"

import fit from "@shared/helpers/fit"

import type { JeopardAiUser, Question } from "../types"

import { UserStatus, Module } from "../enums"

import { addCommasToNumber } from "../helpers"

import AppUserCard from "@/components/UserCard/AppUserCard.vue"
import JeopardAiCountdown from "./JeopardAiCountdown.vue"

import useViewer from "../use/useViewer"
import useIsViewerHostLike from "../use/useIsViewerHostLike"
import useJeopardAiState from "../use/useJeopardAiState"
import useStore from "@/use/useStore"

const MIN_WIDTH = 100
const MAX_WIDTH = 600

export default defineComponent({
  name: "JeopardAiUsersGrid",
  components: {
    JeopardAiStars: () => import("./JeopardAiStars.vue"),
    AppUserCard,
    ResizeObserver,
    JeopardAiCountdown
  },
  props: {
    question: {
      type: Object as PropType<Question>,
      required: true
    },
    max: {
      type: Number,
      default: MAX_WIDTH,
      required: false
    },
    min: {
      type: Number,
      default: MIN_WIDTH,
      required: false
    },
    users: {
      type: Array as PropType<JeopardAiUser[]>,
      required: true
    }
  },
  data() {
    return {
      width: 0,
      height: 0
    }
  },
  setup(props) {
    const { users, question } = toRefs(props)
    const { store } = useStore()
    const isHost = computed(() => store.getters["auth/isHost"])
    const { state } = useJeopardAiState()
    const { isViewerHostLike } = useIsViewerHostLike()
    const { viewer } = useViewer()

    const finale = computed(() => state.value?.module === Module.FINALE)
    const double = computed(() => question.value.double === true)
    const teams = computed(() => store.getters.chats)

    const complete = computed(() => {
      if (finale.value) return false
      if (users.value.length === 0) return false
      return users.value.every(
        user =>
          user.status === UserStatus.CORRECT || user.status === UserStatus.WRONG
      )
    })

    function getTeamName(teamID) {
      return teams.value?.[teamID]?.name
    }

    function isCurrent(userID) {
      return userID === viewer.value.id
    }

    return {
      isCurrent,
      getTeamName,
      teams,
      UserStatus,
      addCommasToNumber,
      complete,
      viewer,
      isViewerHostLike,
      finale,
      isHost,
      double
    }
  },
  mounted() {
    this.resize()
  },
  computed: {
    size() {
      const { width, height } = this
      if (width === 0 || height === 0) return 0
      const items = this.users
      const n = items.length
      if (!n) return 0
      const value = fit(width, height, n) - 2
      return Math.floor(Math.max(this.min, Math.min(value, this.max))) - 1
    }
  },
  methods: {
    resize() {
      this.height = 0
      this.width = 0
      this.$nextTick(() => {
        this.height = this.$refs.grid?.offsetHeight || 0
        this.width = this.$refs.grid?.offsetWidth || 0
      })
    }
  }
})
