<template>
  <div class="jeopardai-countdown">
    <countdown
      class="jeopardai-countdown__time"
      :interval="1000"
      :time="time"
      v-slot="{ hours, minutes, seconds, totalSeconds }"
    >
      <span
        :class="{
          ['jeopardai-countdown__time--late']: totalSeconds === 0,
          ['jeopardai-countdown__time--alert']:
            totalSeconds <= 10 && totalSeconds > 0
        }"
      >
        <template v-if="hours">{{ hours }}:</template>
        <template v-if="minutes">
          {{ 10 > minutes ? `${minutes}` : minutes }}:</template
        >{{ 10 > seconds ? `0${seconds}` : seconds }}
      </span>
    </countdown>
  </div>
</template>

<script>
export default {
  name: "JeopardAiCountdown",
  props: {
    time: {
      required: true,
      type: Number
    }
  }
}
</script>

<style lang="scss">
.jeopardai-countdown {
  background: #292932;
  box-sizing: border-box;
  border: 1px solid rgba(183, 183, 183, 0.53);
  border-radius: 16px;
  padding: 0 15px 3px 17px;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  min-width: 75px;
  text-align: center;
  line-height: 28px;
  height: 32px;

  &__time {
    text-align: left;
    color: #00e599;
    padding: 2px 10px 2px;
    border-radius: 8px;

    &--alert {
      animation-duration: 1.25s;
      animation-iteration-count: infinite;
      animation-name: alert;
    }

    &--late {
      color: $color-wrong;
      animation-duration: 1.2s;
      animation-name: late;
      animation-fill-mode: forwards;
    }
  }

  @keyframes alert {
    0% {
      transform: scale(1);
      color: $color-grey-light1;
    }
    50% {
      transform: scale(1.05);
      color: $color-wrong;
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes late {
    0% {
      color: $color-wrong;
    }
    7% {
      color: $color-wrong;
    }
    14% {
      color: $color-wrong;
    }
    21% {
      color: $color-wrong;
    }
    28% {
      color: $color-wrong;
    }
    35% {
      color: $color-wrong;
    }
    42% {
      color: $color-wrong;
    }
    49% {
      color: $color-white;
    }
    56% {
      color: $color-wrong;
    }
    64% {
      color: $color-wrong;
    }
    71% {
      color: $color-wrong;
    }
    78% {
      color: $color-wrong;
    }
    85% {
      color: $color-wrong;
    }
  }
}
</style>
