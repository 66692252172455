










import { defineComponent } from "@vue/composition-api"
import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"

export default defineComponent({
  name: "JeopardAiQuestionText",
  components: { ResizableText },
  props: {
    value: {
      type: String,
      default: undefined
    }
  }
})
