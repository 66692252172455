import { child } from "firebase/database"
import { Ref, computed } from "@vue/composition-api"

import useJeopardAiRootRef from "./useJeopardAiRootRef"
import { State } from "../types"

export default function useJeopardAiQueueRef(
  round: Ref<number>,
  state: Ref<State>
) {
  const { ref: rootRef } = useJeopardAiRootRef(round)

  const ref = computed(() => {
    if (rootRef.value == null) return undefined
    const questionId = state.value?.questionId
    if (questionId == null) return undefined
    return child(rootRef.value, `question/${questionId}/queue`)
  })

  return { ref }
}
