











import { defineComponent, toRefs } from "@vue/composition-api"

export default defineComponent({
  name: "JeopardAiTitle",
  props: {
    value: {
      required: true,
      type: String
    }
  },
  setup(props) {
    const { value } = toRefs(props)

    let fontSize = 30

    if (value.value.length > 31) {
      fontSize = 18
    } else if (value.value.length > 15) {
      fontSize = 25
    }

    return { fontSize }
  }
})
