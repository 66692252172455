import {
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
  computed
} from "@vue/composition-api"
import type { Ref } from "@vue/composition-api"
import { chain, throttle } from "lodash"
import { onValue } from "firebase/database"

import type { Unsubscribe, DatabaseReference } from "firebase/database"

import { State } from "../types"

import useJeopardAiQueueRef from "./useJeopardAiQueueRef"
import useJeopardAiRound from "./useJeopardAiRound"

import type { QueueItem } from "../types"

let unsubscribe: Unsubscribe

const { round } = useJeopardAiRound()

const _state = ref<State>()

const { ref: queueRef } = useJeopardAiQueueRef(round, _state)

const count = ref(0)
const queue = ref<QueueItem[]>([])

const dedupedQueue = computed(() =>
  chain(queue.value)
    .sortBy(item => item.timestamp)
    .sortBy(item => !item.challenge)
    .uniqBy(item => item.teamId)
    .value()
)

const enabled = computed(() => count.value > 0)

const databaseRef = computed<DatabaseReference | undefined>(() => {
  if (enabled.value === false) return undefined

  return queueRef.value
})

watch(databaseRef, value => {
  if (unsubscribe != null) unsubscribe()
  if (value == null) return

  unsubscribe = onValue(
    queueRef.value,
    throttle(function (snapshot) {
      const value: Record<string, QueueItem> = snapshot.val()
      queue.value = Object.entries(value ?? {}).map(([key, value]) => ({
        id: key,
        ...value
      }))
    }, 500)
  )
})

export default function useJeopardAiQueue(state: Ref<State>) {
  onMounted(() => {
    if (state.value) {
      _state.value = state.value
    }
    count.value++
  })

  onBeforeUnmount(() => {
    count.value--
  })

  return { queue, dedupedQueue }
}
