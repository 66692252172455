var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jeopardai-question-text" },
    [
      _c("ResizableText", {
        staticStyle: { position: "absolute", width: "100%", height: "100%" },
        attrs: { message: _vm.value },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }