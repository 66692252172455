export function addCommasToNumber(number: number): string {
  const negative = number < 0

  if (negative) {
    number = number * -1
  }

  // Convert the number to a string
  let numStr: string = number.toString()

  // Split the string into integer and decimal parts (if any)
  let parts: string[] = numStr.split(".")
  let integerPart: string = parts[0]
  let decimalPart: string = parts[1] || ""

  // Add commas to the integer part
  let integerWithCommas: string = ""
  for (
    let i: number = integerPart.length - 1, j: number = 0;
    i >= 0;
    i--, j++
  ) {
    if (j > 0 && j % 3 === 0) {
      integerWithCommas = "," + integerWithCommas
    }
    integerWithCommas = integerPart[i] + integerWithCommas
  }

  // Combine the integer part and decimal part (if any)
  let result: string = integerWithCommas
  if (decimalPart.length > 0) {
    result += "." + decimalPart
  }

  if (negative) {
    return "-" + result
  }

  return result
}
