







import { computed, defineComponent, toRefs } from "@vue/composition-api"
import { addCommasToNumber } from "../helpers"

export default defineComponent({
  name: "JeopardAiPrice",
  props: {
    amount: {
      type: Number,
      default: undefined
    }
  },
  setup(props) {
    const { amount } = toRefs(props)
    const text = computed(() => addCommasToNumber(amount.value))
    return { text }
  }
})
