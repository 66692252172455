var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "big-ugly-button" },
    [_vm._m(0), _vm._t("default")],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "big-ugly-button__cnt" }, [
      _c("div", { staticClass: "big-ugly-button__text" }, [_vm._v("Buzzer")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }