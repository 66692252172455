import { Ref, computed, ref, toRefs, watch } from "@vue/composition-api"
import { JeopardAiUser, Question, State, Team, User } from "../types"
import { AnswersMode, UserStatus } from "../enums"
import AnswerFactory from "../factories/AnswerFactory"
import useConfirm from "@/use/useConfirm"
import useJeopardAiAnswers from "./useJeopardAiAnswers"
import useJeopardAiQueue from "./useJeopardAiQueue"
import useJeopardAiAnswersRef from "./useJeopardAiAnswersRef"
import useJeopardAiRound from "./useJeopardAiRound"
import useJeopardAiQueueRef from "./useJeopardAiQueueRef"
import useJeopardAiRootRef from "./useJeopardAiRootRef"
import useTeams from "@/use/useTeams"
import useStore from "@/use/useStore"
import useViewer from "./useViewer"
import useIsViewerHostLike from "./useIsViewerHostLike"
import useUsers from "./useUsers"
import useJeopardAiTeamId from "./useJeopardAiTeamId"
import { TIME_TO_ANSWER } from "../constants"
import { child, push, serverTimestamp, set, update } from "firebase/database"
import Sfx from "@shared/Sfx"

import { addCommasToNumber } from "../helpers"
import useIsViewerPresenter from "./useIsViewerPresenter"
import useJeopardPredefined from "./useJeopardPredefined"

export default function useJeopardAiQuestionUI(props) {
  const { question, state, mode } = toRefs(props) as {
    state: Ref<State>
    question: Ref<Question>
    mode: Ref<AnswersMode>
  }
  const answerFactory = new AnswerFactory(question.value)
  const { confirm } = useConfirm()
  const { answers } = useJeopardAiAnswers()
  const { queue, dedupedQueue } = useJeopardAiQueue(state)
  const { ref: answersRef } = useJeopardAiAnswersRef()
  const { round } = useJeopardAiRound()
  const { ref: queueRef } = useJeopardAiQueueRef(round, state)
  const { ref: rootRef } = useJeopardAiRootRef(round)
  const { predefined } = useJeopardPredefined()
  const { teams } = useTeams()
  const { store } = useStore()
  const { viewer } = useViewer()
  const { isViewerHostLike } = useIsViewerHostLike()
  const { isViewerPresenter } = useIsViewerPresenter()
  const { users } = useUsers()
  const { teamId } = useJeopardAiTeamId()
  const unverified = computed(
    () => finale.value && state.value?.finale?.verified == null
  )

  function verify() {
    update(rootRef.value, {
      [`state/finale/verified`]: true,
      [`state/timestamp`]: serverTimestamp()
    })
  }
  const teamQueueItem = computed(() =>
    dedupedQueue.value.find(item => item.teamId === viewer.value.teamID)
  )

  const time = ref(Date.now())

  const OFFSET = 5000

  function playSfx(sfx) {
    store.dispatch("soundeffect/updateGameSoundEffect", sfx)
  }

  const amount = computed(() => {
    if (isViewerHostLike.value) return question.value?.amount ?? 0
    const user = players.value.find(user => user.teamID === viewer.value.teamID)
    if (user == null) return question.value?.amount ?? 0
    return (
      answerFactory.create(user, true).amount ?? question.value?.amount ?? 0
    )
  })

  const min = computed(() => {
    const [question] = Object.values(state.value?.questions ?? []).sort(
      (a, b) => b.amount - a.amount
    )
    return question?.amount ?? 0
  })

  const current = computed(() =>
    answers.value.filter(answer => answer.questionId === question.value?.id)
  )
  const finale = computed(() => mode.value === AnswersMode.FINALE)

  const count = ref(-1)

  const double = computed(() => question.value?.double === true)

  const answer = computed(() => {
    if (isViewerHostLike.value === true && isQuestionVisible.value === false)
      return undefined

    if (isViewerHostLike.value === false && complete.value === false)
      return undefined

    return question.value?.answer
  })

  const challenger = computed(() => players.value.find(user => user.challenge))

  const challenge = computed(() => {
    if (challenger.value == null) return undefined
    return queue.value.find(item => item.userId === challenger.value?.id)
  })

  function getUserTotalAmount(user) {
    return answers.value.reduce((acc, val) => {
      if (val.teamId !== user.teamID) return acc
      return acc + val.amount
    }, 0)
  }

  const players = computed<JeopardAiUser[]>(() => {
    let first

    const challenge = dedupedQueue.value.find(item => {
      if (current.value.length === 0) return item.challenge === true

      return (
        item.challenge === true &&
        current.value.every(
          answer =>
            answer.userId !== item.userId ||
            answer.timestamp + OFFSET > time.value
        )
      )
    })

    if (challenge != null) {
      const user = users.value.find(user => user.id === challenge.userId)
      if (user != null) {
        let status: UserStatus
        if (
          current.value.some(
            answer => answer.userId === user.id && answer.correct
          )
        ) {
          status = UserStatus.CORRECT
        } else if (
          current.value.some(
            answer => answer.userId === user.id && answer.correct === false
          )
        ) {
          status = UserStatus.WRONG
        } else {
          status = UserStatus.ACTIVE
        }

        return [
          {
            ...user,
            selected: false,
            amount: getUserTotalAmount(user),
            status,
            challenge: true,
            challengeTimestamp: challenge.timestamp
          }
        ]
      }
    }

    return dedupedQueue.value.reduce((acc, val, index) => {
      if (index > 5) return acc

      const user = users.value.find(user => user.id === val.userId)
      if (user == null) return acc

      let status: UserStatus
      let deadline: number | undefined

      if (finale.value) {
        if (val.answer == null) {
          status = UserStatus.PENDING
        } else if (
          current.value.some(
            answer => answer.userId === user.id && answer.correct
          )
        ) {
          status = UserStatus.CORRECT
        } else if (
          current.value.some(
            answer => answer.userId === user.id && answer.correct === false
          )
        ) {
          status = UserStatus.WRONG
        } else if (first == null) {
          first = user.id
          status = UserStatus.ACTIVE
        } else {
          status = UserStatus.WAITING
        }
      } else {
        if (
          current.value.some(
            answer => answer.userId === user.id && answer.correct
          )
        ) {
          status = UserStatus.CORRECT
        } else if (current.value.some(answer => answer.correct)) {
          status = UserStatus.PENDING
        } else if (
          current.value.some(
            answer => answer.userId === user.id && answer.correct === false
          )
        ) {
          status = UserStatus.WRONG
        } else if (first == null) {
          first = user.id
          status = UserStatus.ACTIVE

          const [answer] = current.value.sort(
            (a, b) => b.timestamp - a.timestamp
          )

          const timestamp = answer?.timestamp ?? val.timestamp

          deadline = timestamp + TIME_TO_ANSWER
        } else {
          status = UserStatus.PENDING
        }
      }

      acc.push({
        ...user,
        selected: false,
        amount: getUserTotalAmount(user),
        status,
        deadline,
        wager: val.amount,
        answer: val.answer
      })

      return acc
    }, [])
  })

  watch(
    players,
    value => {
      store.commit("JeopardAi/UPDATE_USERS", value)
    },
    { immediate: true }
  )

  const hasPlayers = computed(() => players.value.length > 0)

  const complete = computed(() => {
    if (finale.value) {
      if (players.value.length === 0) return false
      if (
        players.value.every(
          user =>
            user.status === UserStatus.CORRECT ||
            user.status === UserStatus.WRONG
        )
      )
        return true

      return false
    }

    return current.value.some(answer => answer.correct)
  })

  const incomplete = computed(() => !complete.value)

  const self = computed(() =>
    (dailyDoubleTeam.value || finale.value) && !teamQueueItem.value
      ? {
          ...viewer.value,
          amount: getUserTotalAmount(viewer.value)
        }
      : players.value.find(({ id }) => id === viewer.value.id)
  )

  const isQuestionVisible = computed(() => {
    if (isViewerHostLike.value === true) {
      if (double.value === false) return true

      if (players.value.some(user => user.wager != null)) return true

      return false
    }

    if (challenge.value != null) return true

    if (complete.value === true) return true

    if (double.value) {
      if (players.value.length === 0) return false

      return players.value.some(user => user.wager != null)
    }

    if (finale.value) {
      if (players.value.length === 0) return false

      return players.value.some(
        user =>
          user.teamID === viewer.value?.teamID &&
          user.wager != null &&
          (user.answer != null || user.id !== viewer.value.id)
      )
    }

    return true
  })

  const wagering = computed(() => {
    if (
      challenge.value != null ||
      isViewerPresenter.value ||
      isViewerHostLike.value
    ) {
      return false
    }

    if (finale.value === true) {
      return !self.value?.wager || !self.value?.answer
    }

    if (double.value === true) {
      return (
        !teamQueueItem.value &&
        self.value?.wager == null &&
        dailyDoubleTeam.value.id === viewer.value.teamID
      )
    }

    return false
  })

  const wager = computed(() => {
    if (scribes.value.every(user => user.id !== viewer.value.id)) return false
    return wagering.value
  })

  const dailyDoubleTeam = computed<Team | undefined>(() => {
    if (double.value === false) {
      return undefined
    }
    const team = teams.value[state.value.teamId]
    if (team == null) {
      return undefined
    }
    return { ...team, id: state.value.teamId }
  })

  const hasQueue = computed(() => queue.value.length > 0)
  const hasAnswers = computed(() => current.value.length > 0)

  const button = computed(
    () =>
      !finale.value &&
      isViewerHostLike.value === false &&
      isViewerPresenter.value === false &&
      complete.value === false &&
      queue.value?.every(item => item.teamId !== viewer.value.teamID)
  )

  const scribes = computed<User[]>(() => store.state.JeopardAi.scribes ?? [])

  const representative = computed(() => {
    return scribes.value.find(
      user => user.teamID === viewer.value.teamID && user.id !== viewer.value.id
    )
  })

  const active = computed(() =>
    players.value.find(user => user.status === UserStatus.ACTIVE)
  )

  watch(
    active,
    value => {
      teamId.value = value?.teamID
    },
    { immediate: true }
  )

  function fail() {
    push(
      answersRef.value,
      answerFactory.create(active.value, false, answers.value)
    )
    if (finale.value) return
    playSfx(Sfx.WRONG)
  }

  async function regenerate() {
    const confirmed = await confirm({
      message: `Would you like to regenerate this question?`,
      title: "System"
    })

    if (confirmed === false) return

    if (finale.value) {
      set(queueRef.value, null)
      update(rootRef.value, {
        [`state/finale/id`]: null,
        [`state/finale/answer`]: null,
        [`state/finale/text`]: null,
        [`state/finale/verified`]: null,
        [`state/userId`]: null,
        [`state/teamId`]: null,
        [`state/timestamp`]: serverTimestamp()
      })

      return
    }
    set(queueRef.value, null)
    set(
      child(rootRef.value, `state/questions/${question.value.id}/flagged`),
      true
    )
  }

  function score() {
    push(
      answersRef.value,
      answerFactory.create(active.value, true, answers.value)
    )
    if (finale.value) return
    playSfx(Sfx.CORRECT)
  }

  function reset() {
    update(
      answersRef.value,
      current.value.reduce((acc, val) => {
        acc[val.id] = null
        return acc
      }, {})
    )
    set(queueRef.value, null)
  }

  function submit() {
    playSfx(Sfx.SUBMIT)
    push(queueRef.value, {
      timestamp: serverTimestamp(),
      userId: viewer.value?.id,
      teamId: viewer.value?.teamID ?? null
    })
  }

  function submitFinalWagerAmount(amount: number) {
    const item = queue.value.find(item => item.userId === viewer.value.id)

    if (item != null) return

    push(queueRef.value, {
      userId: viewer.value?.id,
      teamId: viewer.value?.teamID ?? null,
      amount
    })
  }

  function submitWagerAnswer(answer: string) {
    const item = queue.value.find(
      item =>
        item.userId === viewer.value.id &&
        item.answer == null &&
        item.amount != null
    )

    if (item == null) return

    update(child(queueRef.value, item.id), {
      timestamp: serverTimestamp(),
      answer: answer || null
    })
  }

  function submitRegularWagerAmount(amount: number) {
    push(queueRef.value, {
      timestamp: serverTimestamp(),
      userId: viewer.value?.id,
      teamId: viewer.value?.teamID ?? null,
      amount
    })
  }

  function submitWagerAmount(amount: number) {
    if (finale.value) {
      submitFinalWagerAmount(amount)
    } else {
      submitRegularWagerAmount(amount)
    }
  }

  return {
    representative,
    incomplete,
    double,
    time,
    answers,
    amount,
    min,
    challenger,
    challenge,
    finale,
    viewer,
    dailyDoubleTeam,
    count,
    self,
    hasQueue,
    hasAnswers,
    answer,
    submit,
    regenerate,
    players,
    button,
    queue,
    score,
    fail,
    complete,
    reset,
    isViewerHostLike,
    wager,
    wagering,
    isQuestionVisible,
    addCommasToNumber,
    unverified,
    verify,
    active,
    predefined,
    hasPlayers,
    submitWagerAnswer,
    submitWagerAmount
  }
}
