

























































import { defineComponent, ref, toRefs, computed } from "@vue/composition-api"
import type { PropType } from "@vue/composition-api"
import { uniq } from "lodash"

import TextField from "@/components/GroupTeams/Common/Games/TextField.vue"
import ChoiceBtn from "@/components/GroupTeams/Common/Games/GameCardParts/ChoiceBtn.vue"
import OptionsList from "@/components/GroupTeams/Common/Games/GameCardParts/OptionsList.vue"
import MissionCard from "@/components/GroupTeams/Common/Games/GameCardParts/MissionCard.vue"

import { addCommasToNumber } from "../helpers"
import { Question, JeopardAiUser } from "../types"

export default defineComponent({
  name: "JeopardAiWager",
  components: {
    ChoiceBtn,
    OptionsList,
    MissionCard,
    TextField
  },
  emits: ["submitWagerAnswer", "submitWagerAmount"],
  props: {
    min: {
      type: Number,
      default: 0
    },
    question: {
      type: Object as PropType<Question>,
      required: false
    },
    finale: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object as PropType<JeopardAiUser>,
      required: true
    },
    amount: {
      type: Number,
      required: false
    }
  },
  setup(props, { emit }) {
    const { user, min } = toRefs(props)

    const response = computed(() => ({
      amount: user.value?.wager,
      answer: user.value?.answer
    }))

    const total = Math.max(user.value?.amount, min.value)

    const N_OF_OPTIONS = 5

    const values = []

    for (let i = 0; i < N_OF_OPTIONS + 1; i++) {
      const n = (total / N_OF_OPTIONS) * i
      const value = Math.floor(n / 100) * 100
      values.push(value)
    }

    values.push(total)

    const options = ref(uniq(values))

    function submitWagerAnswer(answer: string) {
      emit("submitWagerAnswer", answer)
    }

    function submitWagerAmount(option: number | string) {
      let n: number

      if (typeof option === "number") {
        n = option
      } else if (typeof option === "string") {
        n = parseInt(option)
        if (isNaN(n)) return
      } else {
        n = 0
      }

      if (n > total) return
      if (n < 0) return

      emit("submitWagerAmount", n)
    }

    return {
      addCommasToNumber,
      response,
      options,
      submitWagerAmount,
      submitWagerAnswer
    }
  }
})
